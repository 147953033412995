import React from 'react';
import createReactClass from 'create-react-class';
import Header from "./bricks/Header";

import './App.css';

const App = createReactClass({
  render() {
    return (
      <div className="App">
        <Header/>
      </div>
    );
  }
});

export default App;
